<script lang="tsx">
import { defineComponentCoreIcon } from '@core/app/components/core/icon/CoreIcon.vue'

export default defineComponentCoreIcon(
    <svg xmlns="http://www.w3.org/2000/svg"
        class="sim-icon-chevron"
        viewBox="0 0 512 575"
        fill="currentColor"
    >
        <path d="M239 401c9.4 9.4 24.6 9.4 33.9 0L465 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-175 175L81 175c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9L239 401z"/>
    </svg>
)
</script>

<style lang="scss" scoped>

.sim-icon-chevron {
    transition: transform 300ms cubic-bezier(.4,0,.2,1);
}

</style>
